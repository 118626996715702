<template lang="pug">
  div
    v-row
      v-col
        v-data-table(class="elevation-1" hide-default-footer :headers="headers" :items="actions" :loading="loading" loading-text="Загружается... Пожалуйста подождите")
          template(v-slot:item.action="{ item }")
            Export(:excel="excel()" notypes)
            v-btn(rounded small @click="del" v-if="!isCustomer && reports.length > 0")
                v-icon mdi-delete
    v-row
      v-col
        v-data-table(class="elevation-1" :headers="rheaders" :items="reports")
</template>


<script>
    import { tradeactionService } from '@/_services'
    import { mapGetters } from "vuex";
    import Export from '../Export'

    export default {
      components: {
        Export
      },
      props: ['id'],
      data: () => ({
        headers: [
          { text: 'Время акции', value: 'time' },
          { text: 'Название', value: 'name' },
          { text: 'Сеть', value: 'network' },
          { text: 'Действия', value: 'action' }
        ],
        rheaders: [
          { text: 'Адрес', value: 'address' },
          { text: 'Продукт', value: 'product' },
          { text: 'Начало', value: 'stime' },
          { text: 'Окончание', value: 'etime' }
        ],
        actions: [],
        reports: [],
        loading: false
      }),
      computed: {
        ...mapGetters("authentication", ["checkRole"]),
        isCustomer() {
          return this.checkRole(0)
        },
      },
      mounted() {
        this.load()
      },
      methods: {
        load() {
          tradeactionService.report(this.id).then(data => {
            this.actions.push({
              time: `${data.action.startDate} - ${data.action.endDate}`,
              name: data.action.name,
              network: data.action.networkName
            });
            for(let i of data.items) {
              this.reports.push({
                address: i.address,
                product: i.product,
                stime: data.action.startDate,
                etime: data.action.endDate
              })
            }
          })
        },
        excel() {
          return {
            method: tradeactionService.excel,
            id: this.id,
            params: {}
          }
        },
        del() {
          tradeactionService.reportremove(this.id).then(data => {
            this.reports = []
          })
        }
      }
    }
</script>